import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as i1 from '@angular/cdk/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/flex-layout/flex';
import * as i4 from '@angular/flex-layout/extended';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
 * ## A component that creates header component.
 *
 * You can pass input within the oculus-header:
 *
 * - brand - project brand name
 * - brandColor - project brand name color either *primary*(blue) or *gradient* (engie gradient color)
 *
 * You can project contents within the oculus-header.
 *
 * - oculus-header-actions - project contents in the right part of the header title
 * - oculus-header-sub-title - project contents in the below of header. Example is breadcrumbs
 * - custom [no selector] - project contents in the header to fully customize the header
 *
 * @usageNotes
 *
 *
 * #### app.module.ts
 *
 * ```ts
 * import { CommonModule } from '@angular/common';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { FooterModule } from '@oculus/components/footer';
 *
 * import { AppRoutingModule } from './app-routing.module';
 * import { AppComponent } from './app.component';
 *
 * @NgModule({
 *   declarations: [HeaderComponent],
 *   imports: [
 *     CommonModule,
 *     FlexLayoutModule,
 *     BrowserAnimationsModule,
 *     ScrollingModule,
 *   ],
 *   exports: [HeaderComponent],
 * })
 * export class HeaderModule {}
 * ```
 *
 * #### app.component.html
 *
 * ```ts
 * <oculus-header brand="BrandName" brandColor="primary">
 * <div oculus-header-actions>tool bar</div>
 * <div oculus-header-sub-title> Breadcrumbs </div>
 * </oculus-header>
 * <router-outlet></router-outlet>
 *
 * or
 *
 * <oculus-header>
 * <div>tool bar</div>
 * </oculus-header>
 * <router-outlet></router-outlet>
 * ```
 */
const _c0 = ["*", [["", "oculus-header-actions", ""]], [["", "oculus-header-sub-title", ""]]];
const _c1 = ["*", "[oculus-header-actions]", "[oculus-header-sub-title]"];
const _c2 = a0 => ({
  "oculus-header-scroll": a0
});
function HeaderComponent_h1_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h1", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.brandClass);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.brand, " ");
  }
}
class HeaderComponent {
  get brandClass() {
    return this.brandColor === 'gradient' ? 'oculus-header-brand--gradient' : 'oculus-header-brand--primary';
  }
  ngOnInit() {
    this.isScroll$ = this.scrollDispatcher.scrolled().pipe(map(event => {
      const component = document.getElementById('oculus-header');
      return component?.clientHeight && event?.measureScrollOffset('top') > component?.clientHeight - 2 ? true : false;
    }), tap(() => this.detection.detectChanges()));
  }
  constructor(scrollDispatcher, detection) {
    this.scrollDispatcher = scrollDispatcher;
    this.detection = detection;
    this.brand = '';
    this.brandColor = '';
    this.isScroll$ = of(false);
  }
  static {
    this.ɵfac = function HeaderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HeaderComponent)(i0.ɵɵdirectiveInject(i1.ScrollDispatcher), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HeaderComponent,
      selectors: [["oculus-header"]],
      inputs: {
        brand: "brand",
        brandColor: "brandColor"
      },
      ngContentSelectors: _c1,
      decls: 10,
      vars: 6,
      consts: [["id", "oculus-header", 1, "oculus-header-container", 3, "ngClass"], [1, "oculus-header-custom"], ["fxLayout", "column", 1, "oculus-header-content"], ["fxLayout", "row", "fxLayoutAlign", "space-between center", "fxFill", "", 1, "oculus-header-title"], ["class", "oculus-header-brand", 3, "ngClass", 4, "ngIf"], [1, "oculus-header-sub-title"], [1, "oculus-header-brand", 3, "ngClass"]],
      template: function HeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 2)(5, "div", 3);
          i0.ɵɵtemplate(6, HeaderComponent_h1_6_Template, 2, 2, "h1", 4);
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(8, "div", 5);
          i0.ɵɵprojection(9, 2);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c2, i0.ɵɵpipeBind1(1, 2, ctx.isScroll$)));
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("ngIf", ctx.brand);
        }
      },
      dependencies: [i2.NgClass, i2.NgIf, i3.DefaultLayoutDirective, i3.DefaultLayoutAlignDirective, i3.FlexFillDirective, i4.DefaultClassDirective, i2.AsyncPipe],
      styles: [".oculus-header-container[_ngcontent-%COMP%]{width:100%;top:0;padding:0;margin:0;background-color:transparent}.oculus-header-content[_ngcontent-%COMP%]{margin-right:15px!important;margin-left:10px!important}.oculus-header-sub-title[_ngcontent-%COMP%]{margin-bottom:10px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderComponent, [{
    type: Component,
    args: [{
      selector: 'oculus-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n  class=\"oculus-header-container\"\n  id=\"oculus-header\"\n  [ngClass]=\"{ 'oculus-header-scroll': (isScroll$ | async) }\"\n>\n  <div class=\"oculus-header-custom\">\n    <ng-content></ng-content>\n  </div>\n\n  <div class=\"oculus-header-content\" fxLayout=\"column\">\n    <div\n      class=\"oculus-header-title\"\n      fxLayout=\"row\"\n      fxLayoutAlign=\"space-between center\"\n      fxFill\n    >\n      <h1 *ngIf=\"brand\" class=\"oculus-header-brand\" [ngClass]=\"brandClass\">\n        {{ brand }}\n      </h1>\n      <ng-content select=\"[oculus-header-actions]\"></ng-content>\n    </div>\n\n    <div class=\"oculus-header-sub-title\">\n      <ng-content select=\"[oculus-header-sub-title]\"></ng-content>\n\n    </div>\n  </div>\n</div>\n",
      styles: [".oculus-header-container{width:100%;top:0;padding:0;margin:0;background-color:transparent}.oculus-header-content{margin-right:15px!important;margin-left:10px!important}.oculus-header-sub-title{margin-bottom:10px}\n"]
    }]
  }], function () {
    return [{
      type: i1.ScrollDispatcher
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    brand: [{
      type: Input
    }],
    brandColor: [{
      type: Input
    }]
  });
})();
class HeaderModule {
  static {
    this.ɵfac = function HeaderModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HeaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: HeaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FlexLayoutModule, BrowserAnimationsModule, ScrollingModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderModule, [{
    type: NgModule,
    args: [{
      declarations: [HeaderComponent],
      imports: [CommonModule, FlexLayoutModule, BrowserAnimationsModule, ScrollingModule],
      exports: [HeaderComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { HeaderComponent, HeaderModule };
