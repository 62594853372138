import { TooltipModule } from 'prosumer-app/libs/eyes-shared/modules/tooltip/tooltip.module';

import { inject, NgModule } from '@angular/core';
import { DrawerModule } from '@oculus/components/drawer';
import { FooterModule } from '@oculus/components/footer';
import { HeaderModule } from '@oculus/components/header';

import { CaseRootModule } from './+case/case-root.module';
import { ProjectRootModule } from './+project/project-root.module';
import { RenewableProfileRootModule } from './+renewableprofile/renewable-profile-root.module';
import { ScenarioRootModule } from './+scenario/scenario-root.module';
import { SharedLandingPageResolver } from './+share/components/shared-landing-page/shared-landing-page-resolver.service';
import { UserPreferencesService } from './+user-preferences/services/user-preferences/user-preferences.service';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NotificationToolbarModule } from './shared/components/notification-toolbar/notification-toolbar.module';
import { UserProfileModule } from './shared/modules/user-profile/user-profile.module';

@NgModule({
  imports: [
    CoreModule,
    /* Root Modules */
    ProjectRootModule,
    CaseRootModule,
    ScenarioRootModule,
    RenewableProfileRootModule,
    /* Oculus Modules */
    DrawerModule,
    FooterModule,
    HeaderModule,
    UserProfileModule,
    TooltipModule,
    NotificationToolbarModule,
  ],
  providers: [SharedLandingPageResolver],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  readonly preferences = inject(UserPreferencesService);
}
