import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {
  RenewableProfileEffects,
  RenewableProfileFacadeService,
  renewableProfileFeature,
  renewableProfileReducer,
} from './state';
import { RenewableProfileApiService } from './services';

@NgModule({
  imports: [
    StoreModule.forFeature(renewableProfileFeature, renewableProfileReducer),
    EffectsModule.forFeature([RenewableProfileEffects]),
  ],
  providers: [RenewableProfileApiService, RenewableProfileFacadeService],
})
export class RenewableProfileRootModule {}
