import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CaseApiService, CaseFacadeService } from '../+case';
import { ProjectApiService } from './services';
import {
  ProjectFacadeService,
  ProjectEffects,
  projectFeature,
  projectReducer,
} from './state';

@NgModule({
  imports: [
    StoreModule.forFeature(projectFeature, projectReducer),
    EffectsModule.forFeature([ProjectEffects]),
  ],
  providers: [
    ProjectApiService,
    ProjectFacadeService,
    CaseApiService,
    CaseFacadeService,
  ],
})
export class ProjectRootModule {}
