import { FEATURES } from 'prosumer-app/app-routing.module';
import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { ProsumerRoutePathService } from 'prosumer-core/services/prosumer-route-path';
import { UserSurveyFormComponent } from 'prosumer-shared/modules/user-survey-form';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'prosumer-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  @Input() userAttributes: Record<string, string> = {};
  @Input() plan: string;
  @Input() planStyle: { [style: string]: any };
  @Input() loading = true;
  @Input() icon: string;
  @Output() logoutClick: EventEmitter<any> = new EventEmitter();
  fullName: string;

  constructor(
    private _dialogService: MatDialog,
    private _config: ConfigService,
    private _routerPathService: ProsumerRoutePathService,
  ) {}

  ngOnInit(): void {
    this.fullName =
      `${this.userAttributes?.given_name} ${this.userAttributes?.family_name}`.toLocaleLowerCase();
  }

  onClickLogout(): void {
    this.logoutClick.emit();
  }

  onClickFeedback(): void {
    this._dialogService.open(UserSurveyFormComponent, {
      width: '500px',
      disableClose: true,
      data: {
        endPoint: generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.feedback.feedback,
        ),
        userPrefEndpoint: generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.user.userPreferences,
        ),
      },
    });
  }

  resolveUserPreferencesPath() {
    return this._routerPathService.userPreferences();
  }

  get userPreferencesIcon() {
    return FEATURES.userPreferences.icon;
  }

  get userPreferencesName() {
    return FEATURES.userPreferences.name;
  }
}
