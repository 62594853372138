@if (authService?.checkAuth$ | async) {
  <oculus-drawer
    footerLogo="assets/logos/logo-engie-blue.svg"
    footerLogoMini="assets/logos/logo-engie-small-blue.svg"
  >
    <mat-nav-list oculus-drawer-nav>
      @for (link of drawerLinks$ | async; track link.path) {
        <a
          mat-list-item
          class="px-0 rounded-none"
          routerLinkActive="oculus-active-link"
          [attr.data-testid]="'sidenav' + link.path"
          [matTooltipDisabled]="true"
          [matTooltip]="link.label"
          [routerLink]="link.path"
          [disabled]="link.disabled"
        >
          @if (link.icon.indexOf('svg_') === 0) {
            <mat-icon aria-hidden="false" svgIcon="{{ link.icon }}"></mat-icon>
          } @else {
            <mat-icon aria-hidden="false">{{ link.icon }}</mat-icon>
          }
          <span class="text-base content-center">{{ link.label }}</span>
        </a>
      }
    </mat-nav-list>
    <oculus-header>
      <div
        class="prosumer-header-content"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <a
          data-testid="header-brand"
          class="prosumer-header-brand"
          [routerLink]="['/dashboard']"
          ><h2 class="oculus-header-brand--gradient">Prosumer</h2></a
        >
        <div
          class="eyes-breadcrumbs-container"
          *ngIf="authService?.checkAuth$ | async"
          eyes-toolbar-left
        >
          <prosumer-breadcrumb
            [breadcrumbs]="crumbs"
            [reference]="crumbsReferences$ | async"
          ></prosumer-breadcrumb>
        </div>
        <div
          class="toolbar-menu-right"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="1rem"
        >
          <a data-testid="prosumer-about-link" [routerLink]="['/about']"
            ><mat-icon
              aria-hidden="false"
              svgIcon="info_italic"
              class="info-icon"
            ></mat-icon
          ></a>
          <prosumer-notification-toolbar
            eyes-toolbar-right
            *ngIf="authService?.checkAuth$ | async"
            [notifData$]="notificationStore?.nonSilentNotifications$"
            (markAsReadNotifs)="markAsReadNotifications($event)"
            (notificationRouter)="routeToNotification($event)"
          ></prosumer-notification-toolbar>
          <prosumer-user-profile
            [userAttributes]="(authService.user$ | async)?.attributes"
            [plan]="licensePlanService?.licensePlan$ | async"
            [planStyle]="licensePlanService?.licensePlanStyle$ | async"
            [loading]="licensePlanService?.loading$ | async"
            *ngIf="authService?.checkAuth$ | async"
            icon="user_profile"
            (logoutClick)="onClickLogout()"
          ></prosumer-user-profile>
        </div>
      </div>
    </oculus-header>

    <div class="wrapper">
      <router-outlet></router-outlet>
      <div class="push"></div>
    </div>

    <button
      mat-fab
      type="button"
      color="primary"
      prosumerTooltipText="Question? Ask our experts."
      tooltipPosition="left"
      class="ask-support-button"
      [ngClass]="{ 'ask-support-hidden': (isLinkShowing$ | async) }"
      [prosumerTooltip]
      [withDefaultOffsetY]="-10"
      [withDefaultOffsetX]="-10"
      (click)="navigateToSupportPage()"
      data-testid="ask-support"
    >
      <mat-icon aria-hidden="false" svgIcon="svg_ask_support"></mat-icon>
    </button>

    <oculus-footer class="prosumer-footer">
      <small class="prosumer-version" data-testid="prosumer-version">
        <a [href]="coreLink" target="_blank">
          Prosumer {{ coreVersion | async }} - {{ version }}
        </a>
      </small>
      <div data-testid="prosumer-support-link">
        <span>Need help?&nbsp;</span>
        <a
          class="prosumer-support"
          routerLink="support"
          routerLinkActive="prosumer-quick-link-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Ask for Support
        </a>
      </div>
    </oculus-footer>
  </oculus-drawer>
}
