import { NgModule } from '@angular/core';
import { UserProfileComponent } from './user-profile.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    MatMenuModule,
    MatIconModule,
    CommonModule,
    NgxSkeletonLoaderModule,
    RouterModule,
  ],
  exports: [UserProfileComponent],
})
export class UserProfileModule {}
