import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigService, UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { Feature } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FEATURES } from './app-routing.module';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppComponentExts {
  private isEngieImpact = false;

  constructor(
    private readonly config: ConfigService,
    private readonly users: UserFacadeService,
  ) {
    this.subscribeToClientUserPrefsForEngieImpactFlagging();
  }

  selectSideNavLinks(): Observable<Feature[]> {
    return this.selectAllSideNavFeatures();
  }

  isProduction(): boolean {
    return this.config.environment.production;
  }

  isNonEngieImpact(): boolean {
    return !this.isEngieImpact;
  }

  private subscribeToClientUserPrefsForEngieImpactFlagging(): void {
    this.users.clientUserPrefsFeatures$
      .pipe(
        map((features) => this.isMeteomaticsFlagOn(features)),
        untilDestroyed(this),
      )
      .subscribe((isIt) => this.onEngieImpactEvaluation(isIt));
  }

  private onEngieImpactEvaluation(isIt: boolean): void {
    this.isEngieImpact = isIt;
  }

  private isMeteomaticsFlagOn(features: ProsumerFeatures) {
    return features.meteomatics === 1;
  }

  private selectAllSideNavFeatures(): Observable<Feature[]> {
    return this.selectSourceForSideNav().pipe(
      map(() => this.getAllSideNavFeatures()),
      map((features) => this.removePrototypeIfProduction(features)),
      map((features) => this.removeRenewableProfileIfNonEngieImpact(features)),
      map((features) => this.removeUpdatesIfNonEngieImpact(features)),
    );
  }

  private selectSourceForSideNav(): Observable<unknown> {
    return this.users.clientUserPrefsFeatures$.pipe(distinctUntilChanged());
  }

  private getAllSideNavFeatures(): Feature[] {
    return Object.values(FEATURES).filter((feature) => feature.sideNavLink);
  }

  private removePrototypeIfProduction(features: Feature[]): Feature[] {
    return this.isProduction() ? this.removePrototype(features) : features;
  }

  private removePrototype(features: Feature[]): Feature[] {
    return features.filter((feature) => feature.path !== 'prototype');
  }

  private removeRenewableProfileIfNonEngieImpact(
    features: Feature[],
  ): Feature[] {
    return this.isNonEngieImpact()
      ? this.removeRenewableProfile(features)
      : features;
  }

  private removeRenewableProfile(features: Feature[]): Feature[] {
    return features.filter((feature) => feature.path !== 'renewable_profile');
  }

  private removeUpdatesIfNonEngieImpact(features: Feature[]): Feature[] {
    return this.isNonEngieImpact() ? this.removeUpdates(features) : features;
  }

  private removeUpdates(features: Feature[]): Feature[] {
    return features.filter((feature) => feature.path !== 'updates');
  }
}

interface ProsumerFeatures {
  readonly meteomatics: number;
  readonly mobility: number;
}
