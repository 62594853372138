import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import * as i1 from '@angular/flex-layout/flex';
import * as i2 from '@angular/material/divider';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

/**
 * ## A component that creates footer components under an Angular Material mat divider.
 *
 * You can project contents within the oculus-footer.
 *
 * - oculus-footer-custom - project custom footer contents.
 * - *default* [no selector] - project contents in a single row flex layout.
 *
 * @usageNotes
 *
 *
 * #### app.module.ts
 *
 * ```ts
 * import { CommonModule } from '@angular/common';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { FooterModule } from '@oculus/components/footer';
 *
 * import { AppRoutingModule } from './app-routing.module';
 * import { AppComponent } from './app.component';
 *
 * @NgModule({
 *   declarations: [AppComponent],
 *   imports: [
 *     BrowserModule,
 *     CommonModule,
 *     AppRoutingModule,
 *     FooterModule,
 *   ],
 *   bootstrap: [AppComponent],
 * })
 * export class AppModule {}
 * ```
 *
 * #### app.component.html
 *
 * ```ts
 * <router-outlet></router-outlet>
 * <oculus-footer></oculus-footer>
 * ```
 */
const _c0 = ["*", [["", "oculus-footer-custom", ""]]];
const _c1 = ["*", "[oculus-footer-custom]"];
class FooterComponent {
  static {
    this.ɵfac = function FooterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FooterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FooterComponent,
      selectors: [["oculus-footer"]],
      ngContentSelectors: _c1,
      decls: 5,
      vars: 0,
      consts: [[1, "oculus-footer-container"], ["fxLayout", "row", "fxLayoutAlign", "space-between center", 1, "oculus-footer-default"]],
      template: function FooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "mat-divider");
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(4, 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [i1.DefaultLayoutDirective, i1.DefaultLayoutAlignDirective, i2.MatDivider],
      styles: [".oculus-footer-container{bottom:0;width:100%;height:-moz-fit-content;height:fit-content}.oculus-footer-default{padding:.75em!important}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterComponent, [{
    type: Component,
    args: [{
      selector: 'oculus-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      template: "<div class=\"oculus-footer-container\">\n  <mat-divider></mat-divider>\n  <div\n    class=\"oculus-footer-default\"\n    fxLayout=\"row\"\n    fxLayoutAlign=\"space-between center\"\n  >\n    <ng-content></ng-content>\n  </div>\n  <ng-content select=\"[oculus-footer-custom]\"></ng-content>\n</div>\n",
      styles: [".oculus-footer-container{bottom:0;width:100%;height:-moz-fit-content;height:fit-content}.oculus-footer-default{padding:.75em!important}\n"]
    }]
  }], null, null);
})();
class FooterModule {
  static {
    this.ɵfac = function FooterModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FooterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FooterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FlexLayoutModule, MatDividerModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterModule, [{
    type: NgModule,
    args: [{
      declarations: [FooterComponent],
      imports: [CommonModule, FlexLayoutModule, MatDividerModule],
      exports: [FooterComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FooterComponent, FooterModule };
