import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CaseApiService } from './services';
import {
  CaseFacadeService,
  CaseEffects,
  caseFeature,
  caseReducer,
} from './state';

@NgModule({
  imports: [
    StoreModule.forFeature(caseFeature, caseReducer),
    EffectsModule.forFeature([CaseEffects]),
  ],
  providers: [CaseApiService, CaseFacadeService],
})
export class CaseRootModule {}
